var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog page"},[_c('MSectionHeader',{staticClass:"header",attrs:{"title":"Blog"}}),_c('div',{staticClass:"blog__container"},[_c('div',{staticClass:"blog__container--panel mobile-only"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"section__title"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Categorii")))])]),_c('div',{staticClass:"section__content"},[_c('div',{staticClass:"category",class:{
              category__active: _vm.$route.query.category == _vm.allCategory.name,
            },on:{"click":function($event){return _vm.getCategoryArticles(_vm.allCategory.name)}}},[_c('span',[_vm._v(_vm._s(_vm.allCategory.name))]),_c('span',[_vm._v(_vm._s(`(${_vm.totalArticles})`))])]),_vm._l((_vm.categories),function(category,i){return _c('div',{key:i,staticClass:"category",class:{
              category__active: _vm.$route.query.category == category.slug,
            },on:{"click":function($event){return _vm.getCategoryArticles(category.slug)}}},[_c('span',[_vm._v(_vm._s(category.name))]),_c('span',[_vm._v(_vm._s(`(${category.articleCount})`))])])})],2)])]),_c('div',{staticClass:"blog__container--articles"},[_c('transition-group',{staticClass:"articles",attrs:{"appear":"","name":"products__slide","tag":"div"}},_vm._l((_vm.articles),function(article,i){return _c('router-link',{key:i,staticClass:"articles__article--link",attrs:{"to":`/${_vm.$i18n.locale}/blog/${article.slug}`}},[_c('MArticleCard',{staticClass:"articles__article",attrs:{"article":article}})],1)}),1),(_vm.totalPages > 1)?_c('SfPagination',{staticClass:"articles__pagination",attrs:{"current":_vm.currentPage,"total":_vm.totalPages,"visible":3}}):_vm._e()],1),_c('div',{staticClass:"blog__container--panel mobileSpacer"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"section__title"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Articole Recente")))])]),_vm._l((_vm.recentPosts),function(article,i){return _c('router-link',{key:i,staticClass:"articles__article--link",attrs:{"to":`/${_vm.$i18n.locale}/blog/${article.slug}`}},[_c('MRelatedArticle',{staticClass:"recentPost",attrs:{"article":article}})],1)})],2),_c('div',{staticClass:"section desktop-only"},[_c('div',{staticClass:"section__title"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Categorii")))])]),_c('div',{staticClass:"section__content"},[_c('div',{staticClass:"category",class:{
              category__active: _vm.$route.query.category == _vm.allCategory.name,
            },on:{"click":function($event){return _vm.getCategoryArticles(_vm.allCategory.name)}}},[_c('span',[_vm._v(_vm._s(_vm.allCategory.name))]),_c('span',[_vm._v(_vm._s(`(${_vm.totalArticles})`))])]),_vm._l((_vm.categories),function(category,i){return _c('div',{key:i,staticClass:"category",class:{
              category__active: _vm.$route.query.category == category.slug,
            },on:{"click":function($event){return _vm.getCategoryArticles(category.slug)}}},[_c('span',[_vm._v(_vm._s(category.name))]),_c('span',[_vm._v(_vm._s(`(${category.articleCount})`))])])})],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }